import type { FC, MouseEventHandler, TouchEvent } from 'react'
import { useCallback, useMemo, useState } from 'react'
import type { ImageProps } from 'next/future/image'
import Image from 'next/future/image'
import ImagePlaceholder from './assets/placeholder.svg'
import moduleStyles from './ProductImage.module.scss'
import clsx from 'clsx'

export type ProductImageProps = ImageProps & {
  withMagnify?: boolean
  onClickNext?: () => void
  onClickPrev?: () => void
  loading?: 'lazy' | 'eager'
}

const ProductImage: FC<ProductImageProps> = (props) => {
  const { withMagnify = false, src, alt, onClickNext, onClickPrev, loading = 'lazy', ...restImageProps } = props

  const nextImgSrc = `/_next/image?url=${src}&w=3840&q=100`

  // const imageHolderRef = useRef<HTMLDivElement>(null)
  // useMagnify(imageHolderRef, withMagnify)

  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [zoomed, setZoomed] = useState(false)

  const handleMouseOut: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    setX(0)
    setY(0)
    setZoomed(false)
  }, [setX, setY, setZoomed])

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (!withMagnify) return
      const imageRect = (e.target as HTMLDivElement).getBoundingClientRect()

      const hundred = imageRect.width
      const newValue = e.clientX - imageRect.left
      const newPercentValue = (newValue * 100) / hundred

      const hundredY = imageRect.height
      const newValueY = e.clientY - imageRect.top
      const newPercentValueY = (newValueY * 100) / hundredY

      setX(newPercentValue)
      setY(newPercentValueY)
      setZoomed(true)
    },
    [setX, setY, setZoomed, withMagnify]
  )

  const [imageLoaded, setImageLoaded] = useState(false)
  const [error, setError] = useState<null | boolean>(null)
  const [touchPosition, setTouchPosition] = useState<number | null>(null)

  const handleImageLoadingComplete = useCallback<Required<ImageProps>['onLoadingComplete']>(() => {
    setImageLoaded(true)
  }, [])

  const className = useMemo(
    () =>
      clsx(
        'relative',
        withMagnify ? 'hover:cursor-zoom-in' : '',
        imageLoaded ? 'opacity-100' : 'opacity-0',
        'object-contain aspect-[4/3]',
        props.className,
        moduleStyles.image
      ),
    [props.className, withMagnify, imageLoaded]
  )

  if (error) {
    return (
      <div
        className="flex flex-col items-center m-auto object-contain justify-center flex-grow-0 flex-shrink-1 relative
        h-auto w-auto
        "
        style={{ maxHeight: restImageProps.height, maxWidth: restImageProps.width }}
      >
        <ImagePlaceholder width={restImageProps.width} height={restImageProps.height} className="object-contain" />
      </div>
    )
  }
  const handleTouchStart = (e: TouchEvent<HTMLDivElement>): void => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = (e: TouchEvent) => {
    const touchDown = touchPosition
    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const touchSize = touchDown - currentTouch
    touchSize > 5 && onClickNext?.()
    touchSize < -5 && onClickPrev?.()

    setTouchPosition(null)
  }

  return (
    <span className="flex justify-center">
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
        className={clsx({ [moduleStyles.imageHolder]: withMagnify, 'cursor-zoom-in': withMagnify })}
      >
        {!zoomed && (
          <Image
            alt={alt}
            loading={loading}
            priority={false}
            onLoadingComplete={handleImageLoadingComplete}
            onError={setError.bind(this, true)}
            {...restImageProps}
            src={src}
            className={className}
          />
        )}
        {withMagnify && (
          <div
            className={clsx('w-full h-full', { invisible: !zoomed })}
            style={{
              backgroundSize: '125%',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${nextImgSrc})`,
              backgroundPosition: `${x}% ${y}%`,
            }}
          ></div>
        )}
      </div>
    </span>
  )
}

export default ProductImage
