import { CwsA, CwsBreadcrumb, CwsBreadcrumbItem, CwsGrid, CwsGridCol, CwsGridRow, CwsSection } from '@components/cws'
import type { FC } from 'react'
import Link from 'next/link'
import { useBreadCrumbsState } from '@components/BreadCrumbs/State/state'

export const BreadCrumbs: FC = () => {
  const {
    state: { breadcrumbs: breadcrumbsStory },
  } = useBreadCrumbsState()

  return (
    <CwsSection className="max-w-full cws-pb-m">
      <CwsGrid width="fluid">
        <CwsGridRow justifyContent="left">
          <CwsGridCol>
            <CwsBreadcrumb>
              {breadcrumbsStory.map((bc, index, array) => {
                const isLast = index === array.length - 1
                return (
                  <CwsBreadcrumbItem key={`${bc.title}-${index}`} current={isLast}>
                    <Link href={bc.href} passHref>
                      <CwsA hideArrow>{bc.title}</CwsA>
                    </Link>
                  </CwsBreadcrumbItem>
                )
              })}
            </CwsBreadcrumb>
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>
    </CwsSection>
  )
}

export default BreadCrumbs
