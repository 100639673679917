// @ts-ignore
import * as CWS from 'cms-web-styleguide'

export const {
  CwsHeaderWrapper,
  CwsHeader,
  CwsHeaderLeft,
  CwsHeaderNav,
  CwsHeaderNavItem,
  CwsA,
  CwsP,
  CwsTag,
  CwsH1,
  CwsH4,
  CwsH3,
  CwsH2,
  CwsH5,
  CwsHeaderRight,
  CwsHeaderLanguageSelector,
  CwsHeaderLanguageSelectorItem,
  CwsHeaderLocaleSelector,
  CwsHeaderShopping,
  CwsHeaderShoppingHeader,
  CwsHeaderShoppingList,
  CwsHeaderShoppingListItem,
  CwsImg,
  CwsHeaderShoppingListItemDescription,
  CwsDivider,
  CwsButton,
  CwsAlert,
  CwsHeaderButtonContainer,
  CwsHeaderLogo,
  CwsIcon,
  CwsHeaderMenuNav,
  CwsHeaderMenuNavItem,
  CwsHeaderMegamenu,
  CwsHeaderMegamenuCol,
  CwsHeaderMegamenuGrid,
  CwsHeaderSearch,
  CwsHeaderMenu,
  CwsGrid,
  CwsGridRow,
  CwsGridCol,
  CwsCounter,
  CwsHeaderSearchResultList,
  CwsHeaderSearchResultItem,
  CwsSpan,
  CwsHeaderSearchResultLink,
  CwsHeaderShoppingListItemDeleteButton,
  CwsSelect,
  CwsSelectOption,
  CwsAccordion,
  CwsAccordionItemHeader,
  CwsAccordionItemBody,
  CwsAccordionItem,
  CwsCarousel,
  CwsCarouselItem,
  CwsSection,
  CwsIframe,
  CwsInput,
  CwsTextarea,
  CwsTab,
  CwsTabContentItem,
  CwsTabItem,
  CwsTabContent,
  CwsHero,
  CwsForm,
  CwsSelection,
  CwsSelectionItem,
  CwsList,
  CwsListItem,
  CwsFormButton,
  CwsCheckbox,
  CwsModal,
  CwsModalBody,
  CwsModalFooter,
  CwsButtonGroup,
  CwsButtonGroupItem,
  CwsBreadcrumb,
  CwsBreadcrumbItem,
  CwsRadio,
  CwsWrapperSticky,
  CwsHeaderLocale,
  CwsHeaderSearchResultListItem,
  CwsHeaderLocaleSelectorItem,
  CwsHeaderMegamenuItem,
  CwsHeaderMyBooking,
  CwsHeaderProfile,
  CwsHeaderSearchResultListLink,
  CwsCard,
  CwsCardBody,
  CwsCardImg,
  CwsBr,
} = CWS
