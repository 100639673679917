import { CwsA, CwsHeaderMegamenu, CwsHeaderMegamenuItem, CwsHeaderMenuNav, CwsHeaderMenuNavItem } from '@components/cws'
import { getSlug, SEPARATOR } from '@utils/slugs'
import Link from 'next/link'
import { CategoryTip } from '@model/category/CategoryTip'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/dist/client/router'
type CategoriesViewProps = {
  categories: CategoryTip[]
}
export const CategoriesView: FC<CategoriesViewProps> = ({ categories }) => {
  const { locale, query, asPath } = useRouter()
  const isCurrentCategory = useCallback(
    (categoryId: string) => {
      if (asPath.includes('category')) {
        const { slug } = query
        return typeof slug === 'string' && slug.endsWith(categoryId)
      }
      return false
    },
    [query, asPath]
  )

  const [categoryItems, setCategoryItems] = useState<CategoryTip[]>([])
  const prevLocale = useRef<string | undefined>()

  useEffect(() => {
    setCategoryItems(categories)
    prevLocale.current = locale
  }, [locale, categories])

  if (!categoryItems.length) return null

  return (
    <>
      {prevLocale.current === locale && categoryItems.length ? (
        <CwsHeaderMenuNav>
          {categories.map((item) => {
            const icon = item.custom3
            const slug = getSlug(`${item.name}`, item.id)
            let href = query.catalogId
              ? `/${locale}/${query.catalogId}/category/${slug}`
              : `/${locale}/category/${slug}`

            if (item.searchKey === 'clubone') {
              href = query.catalogId
                ? `/${locale}/${query.catalogId}/collection/${item.description}`
                : `/${locale}/collection/${item.description}`
            } else if (item.searchKey === 'brands') {
              href = `/${locale}/${query.catalogId}/brands`
            }

            return (
              <CwsHeaderMenuNavItem
                key={item.id}
                current={isCurrentCategory(item.id + '')}
                // tagNotification={item.isCollection}
                // label={
                //   <span className={s.navItem} onMouseOver={() => setIsOpenCategory(true)}>
                //     {item.name}
                //   </span>
                // }
                label={item.name}
                href={href}
                icon={icon}
              >
                <CwsA href={href} />
                {item.children.length ? (
                  <CwsHeaderMegamenu>
                    {item.children
                      .filter((item) => !!item.children.length)
                      .map((subCategory) => {
                        const slug = getSlug(`${item.name}${SEPARATOR}${subCategory.name}`, subCategory.id)
                        if (!subCategory.children.length) return null

                        const href = {
                          pathname: query.catalogId ? '/[catalogId]/category/[slug]' : '/category/[slug]',
                          query: { catalogId: query.catalogId, slug },
                        }

                        return (
                          <CwsHeaderMegamenuItem
                            key={slug}
                            // title={subCategory.name}
                            title={
                              <Link href={href} passHref>
                                {subCategory.name}
                              </Link>
                            }
                          >
                            {subCategory.children
                              .filter(({ numberOfProducts }) => numberOfProducts > 0)
                              .map((cat) => {
                                const slug = getSlug(
                                  `${item.name}${SEPARATOR}${subCategory.name}${SEPARATOR}${cat.name}`,
                                  cat.id
                                )

                                const href = {
                                  pathname: query.catalogId ? '/[catalogId]/category/[slug]' : '/category/[slug]',
                                  query: { catalogId: query.catalogId, slug },
                                }

                                return (
                                  <Link href={href} passHref key={slug}>
                                    <CwsA hideArrow href={href}>
                                      {cat.name}
                                    </CwsA>
                                  </Link>
                                )
                              })}
                          </CwsHeaderMegamenuItem>
                        )
                      })}
                  </CwsHeaderMegamenu>
                ) : null}
              </CwsHeaderMenuNavItem>
            )
          })}
        </CwsHeaderMenuNav>
      ) : null}
    </>
  )
}
