import type { ClassName, OrderTotal } from '@model/order/OrderTotal'
import type { Order } from '@model/order/Order'
import * as R from 'ramda'
import type { OrderProduct } from '@model/order/OrderProduct'
import { getProductById } from '@api/products'
import type { CountryName, LanguageId } from '@model/locales'
import type { Locale } from '@model/locales'

const shippingTax = R.propEq('className', 'ot_shipping_tax')
const productTax = R.propEq('className', 'ot_tax')
const orderTax = R.anyPass([productTax, shippingTax])

export const getOrderTotalByClassName = (totals: OrderTotal[]) => (className: ClassName) =>
  totals.find((ot) => ot.className === className)

export const getAllTotalsByClassName =
  (totals: OrderTotal[]) =>
  (...classNames: ClassName[]) =>
    totals.filter((ot) => classNames.includes(ot.className))

export const getAllOrderTotalsByClassName = (totals: OrderTotal[]) => (className: ClassName) =>
  totals.filter((ot) => ot.className === className)

export const getOrderTotalTax = (totals: OrderTotal[]) => {
  return R.compose(R.sum, R.map(R.prop('value')), R.filter(orderTax))(totals)
}

export const getGiftCouponsAppliedTotal = ({ orderTotals }: Pick<Order, 'orderTotals'> = { orderTotals: [] }) => {
  const otGetter = getOrderTotalByClassName(orderTotals)
  const giftCards = orderTotals.filter(({ className }) => className === 'ot_gift_coupons')
  const giftCardsTotal = giftCards.length ? R.compose(R.sum, R.map(R.prop('value')))(giftCards) : 0
  const total = otGetter('ot_total')?.value

  if (giftCardsTotal && total) {
    const difference = total - giftCardsTotal
    return { total, totalValueAfterCoupons: difference, giftCardsTotal }
  }
  return { total, totalValueAfterCoupons: total }
}

const getApplicableProducts = R.pathOr([], ['promotions', 0, 'applicableProducts'])
export const getPromotionsCouponCode = R.pathOr('', ['promotions', 0, 'coupon', 'couponCode'])
const getSkus = R.map(R.prop('sku'))
const subApp = R.compose(getSkus, getApplicableProducts)
const transformFreeProductOt = R.map((ot: OrderTotal) => ({ ...ot, applicableProductsSkus: subApp(ot) }))

interface FreeProductTotal extends OrderTotal {
  applicableProductsSkus?: unknown[]
}

export const addFreeProductToOrderProducts = async (
  order: Order,
  sessionId: string | undefined,
  languageId: LanguageId,
  fromConfirmation?: boolean
): Promise<OrderProduct[]> => {
  const otGetter = getAllOrderTotalsByClassName(order.orderTotals)
  const otFreeProducts = otGetter('ot_free_product')

  if (!otFreeProducts.length) return order.orderProducts

  const orderProductsPromises = order.orderProducts.flatMap(async (op) => {
    let freeProduct: FreeProductTotal | undefined = transformFreeProductOt(otFreeProducts).find((ot) =>
      ot.applicableProductsSkus.includes(op.sku)
    )

    if (fromConfirmation && !freeProduct) {
      freeProduct = R.head(otFreeProducts)
    }

    if (freeProduct) {
      const fullFreeProduct = await getProductById(parseFloat(freeProduct.custom1), sessionId, languageId)
      const freeOrderProduct: Partial<OrderProduct> = {
        product: fullFreeProduct,
        id: 0,
        quantity: parseInt(freeProduct.custom5) || 1,
        sku: freeProduct.custom2,
        productId: fullFreeProduct.id,
        finalPriceIncTax: 0,
        finalPriceExTax: 0,
        name: fullFreeProduct.name,
        model: fullFreeProduct.model,
        promotionName: (freeProduct.promotions || []).map((p) => p.name).join(','),
      }
      return [op, freeOrderProduct]
    }
    return [op]
  })

  const orderProducts = await Promise.all(orderProductsPromises)

  return R.compose(
    R.uniqBy((product: Partial<OrderProduct>) => `${product.sku}+${product.promotionName || ''}`),
    R.flatten
  )(orderProducts) as OrderProduct[]
}

export const isEstonianMarket = (locale: Locale) => ['et', 'ru', 'en'].includes(locale)

export const validations = {
  email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  zip: /^(\d{3,5})$/,
  lvZip: /^(LV-|lv-)?[0-9]{4}$/,
  ltZip: /^(LT-|lt-)?[0-9]{5}$/,
  etPhone: /^\+(?:3725)\d{6,7}$/,
  lvPhone: /^\+(?:371[52])\d{6,7}$/,
  telephone:
    /^\+(?:((?:358)[0-9]{5,12})|((?:372)[0-9]{7,10})|([78][0-9]{10})|((?:371|370)[0-9]{8,10})|((?:46)[0-9]{9,14})|((?:47)[0-9]{8,12}))$/,
}

// the lib: https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b
export const zipValidators: Record<CountryName, RegExp> = {
  Estonia: /^\d{5}$/,
  Latvia: /^[Ll][Vv]-\d{4}$/,
  Lithuania: /^[Ll][Tt]-\d{5}$/,
  Finland: /^\d{5}$/,
  Sweden: /^\d{3}\s*\d{2}$/,
}

export const getOrderVatListByClassName = (totals: OrderTotal[]) => (className: ClassName) =>
  totals.find((ot) => ot.className === className && !!ot.value)
